import { Icon, IconsEnum } from 'components/Icons';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { useLazyGetCachedProfileQuery } from 'features/api/userSlice';
import { selectSaldoPrelevabile } from 'features/dashboard/selectors';
import { setIsOpenLoginModalValue } from 'features/modal/modalSlice';
import { useInternalSession } from 'hooks/useInternalSession';
import { getLngFromCultureName } from 'hooks/useLingUI';
import useWindowSize from 'hooks/useWindowSize';
import { useAppDispatch, useTypedSelector } from 'lib/centralStore';
import { getNonce } from 'lib/policy';
import policy from 'lib/policy/types';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useCallback, useState } from 'react';
import { breakpoint } from 'utility/constant';
import { formatAmount, getAmount, isClientSide, isMobileBrowser } from 'utility/functions';
import styles from './ChatTrigger.module.scss';

const { CHAT_SERVICE_URL, DIGITAL_ASSISTANT_URL } = policy;

export type ChatTriggerProps = {
  text?: string;
  isChatForAuthenticated?: boolean;
  className?: string;
};

export const ChatTrigger = ({ text, isChatForAuthenticated = false, className }: ChatTriggerProps) => {
  const { isAuthenticated, session } = useInternalSession();
  const [isLoadedChat, setIsLoadedChat] = useState(false);
  const saldoPrelevabile = useTypedSelector(selectSaldoPrelevabile);

  const dispatch = useAppDispatch();
  const { width } = useWindowSize();
  const isMobile = width! <= breakpoint.sm;
  const [getSessionCacheProfile] = useLazyGetCachedProfileQuery();
  const router = useRouter();

  const { locale } = router;

  const initDigitalAssistant = useCallback(async () => {
    if (session && isChatForAuthenticated) {
      const profileData = await getSessionCacheProfile().unwrap();
      const {
        nome,
        club,
        codfisc,
        cognome,
        cartaceo,
        cod_prom,
        flag_wagering,
        data_creazione_contratto,
        tot_versamenti,
        num_versamento,
        active_cu,
        flag_marketing,
        telefono_cell,
        last_login_date,
        data_scad_doc,
        flag_profilazione,
        data_primo_versamento,
        saldo_carta,
        saldo_wagering,
      } = profileData;

      console.log('Engagent chat successfully loaded');
      let origin = `${process.env.NEXT_PUBLIC_CHAT_SOURCE ?? 'preview'}${isMobileBrowser() ? '-mobile' : ''}`;
      if (isClientSide()) {
        window.DigitalAssistant.UserInfo.username = session.user.name; //'<username>';
        window.DigitalAssistant.UserInfo.displayname = nome + '' + cognome; //"<nome cognome>";
        window.DigitalAssistant.UserInfo.notes = 'dashboard'; //'<nome sezione sito, Esempio: sport>';
      }
      const personal_params = {
        wsc_account_code: session.user.contractCode,
        chat_source: origin,
        showDocumentValidityAlert: true,
        hasUserAuthorizedChatPrivacy: 1,
        club: club,
        cod_prom: cod_prom,
        data_scad_doc: data_scad_doc
          ? data_scad_doc.length === 8
            ? format(
                new Date(
                  `${data_scad_doc.substring(0, 4)}-${data_scad_doc.substring(4, 6)}-${data_scad_doc.substring(6, 8)}`
                ),
                'dd/MM/yyyy'
              )
            : data_scad_doc
          : '',
        data_primo_versamento: data_primo_versamento,
        codfisc: codfisc,
        nome: nome,
        cognome: cognome,
        flag_marketing: flag_marketing === '1' ? true : false,
        flag_profilazione: flag_profilazione === '1' ? true : false,
        cartaceo: cartaceo ? +cartaceo : 0,
        last_login_date: last_login_date,
        data_crea_contr: data_creazione_contratto ? format(parseISO(data_creazione_contratto), 'dd/MM/yyyy') : null,
        num_versamento: num_versamento ? +num_versamento : 0,
        datanasc: format(parseISO(session.user.datanasc), 'dd/MM/yyyy'),
        active_cu: !!active_cu,
        flag_wagering: flag_wagering ? !!+flag_wagering : false,
        tot_versamenti: tot_versamenti ? formatAmount(getAmount(tot_versamenti)) : formatAmount(0),
        e_mail: session.user.email,
        telefono_mobile: telefono_cell,
        saldo_carta: formatAmount(getAmount(saldo_carta)),
        saldo_wagering: formatAmount(getAmount(saldo_wagering)),
        saldo_prelevabile: saldoPrelevabile ? formatAmount(getAmount(saldoPrelevabile)) : undefined,
      };

      let initParams = {};

      if (typeof window?.engagentFormErrorCode !== 'undefined' && typeof window?.engagentFormErrorMsg !== 'undefined') {
        var engagentFormErrorTopic = 'REG_FORM_EVT';
        // var engagentFormError = [engagentFormErrorTopic, window?.engagentFormErrorCode, window?.engagentFormErrorMsg].join('_');
        if (isMobile) {
          initParams = {
            cid: process.env.NEXT_PUBLIC_DIGITAL_ASSISTANT_CID, //'205d106b17323bdccede4dca5681a4c994b54562', // id identificativo SNAI
            key: engagentFormErrorTopic,
            sessionId: 'wsc_account_code',
            baseUrl: CHAT_SERVICE_URL,
            view: isMobile ? { mobileMode: 'detect', status: 'visible' } : { status: 'visible' },
            params: personal_params,
          };
        } else {
          initParams = {
            cid: process.env.NEXT_PUBLIC_DIGITAL_ASSISTANT_CID, //'205d106b17323bdccede4dca5681a4c994b54562', // id identificativo SNAI
            key: engagentFormErrorTopic,
            sessionId: 'wsc_account_code',
            baseUrl: CHAT_SERVICE_URL,
            view: { status: 'visible' },
            params: personal_params,
          };
        }
      } else {
        if (isMobile) {
          initParams = {
            cid: process.env.NEXT_PUBLIC_DIGITAL_ASSISTANT_CID, //'205d106b17323bdccede4dca5681a4c994b54562',
            sessionId: 'wsc_account_code',
            baseUrl: CHAT_SERVICE_URL,
            view: isMobile ? { mobileMode: 'detect', status: 'visible' } : { status: 'visible' },
            params: personal_params,
          };
        } else {
          initParams = {
            cid: process.env.NEXT_PUBLIC_DIGITAL_ASSISTANT_CID, //'205d106b17323bdccede4dca5681a4c994b54562',
            sessionId: 'wsc_account_code',
            baseUrl: CHAT_SERVICE_URL,
            view: { status: 'visible' },
            params: personal_params,
          };
        }
      }
      window?.DigitalAssistant.init(initParams);
    } else {
      if (isClientSide()) {
        try {
          window.DigitalAssistant.UserInfo.username = 'Usr anonimo area registrazione';

          let origin = `${process.env.NEXT_PUBLIC_CHAT_SOURCE ?? 'preview'}${isMobileBrowser() ? '-mobile' : ''}`;

          let initParams = {};

          if (
            typeof window?.engagentFormErrorCode !== 'undefined' &&
            typeof window?.engagentFormErrorMsg !== 'undefined'
          ) {
            var engagentFormErrorTopic = 'REG_FORM_EVT';
            var engagentFormError = [
              engagentFormErrorTopic,
              window?.engagentFormErrorCode,
              window?.engagentFormErrorMsg,
            ].join('_');
            // console.log(engagentFormError);

            if (isMobile) {
              initParams = {
                cid: process.env.NEXT_PUBLIC_DIGITAL_ASSISTANT_CID, //'205d106b17323bdccede4dca5681a4c994b54562',
                key: engagentFormErrorTopic,
                sessionId: 'wsc_account_code',
                view: isMobile ? { mobileMode: 'detect', status: 'visible' } : { status: 'visible' },
                baseUrl: CHAT_SERVICE_URL,
                params: {
                  user_language: getLngFromCultureName(locale),
                  wsc_account_code: '',
                  chat_source: origin,
                  registration_form_error: engagentFormError,
                  showDocumentValidityAlert: true,
                },
              };
            } else {
              initParams = {
                cid: process.env.NEXT_PUBLIC_DIGITAL_ASSISTANT_CID, //'205d106b17323bdccede4dca5681a4c994b54562',
                key: engagentFormErrorTopic,
                sessionId: 'wsc_account_code',
                view: { status: 'visible' },
                baseUrl: CHAT_SERVICE_URL,
                params: {
                  user_language: getLngFromCultureName(locale),
                  wsc_account_code: '',
                  chat_source: origin,
                  registration_form_error: engagentFormError,
                  showDocumentValidityAlert: true,
                },
              };
            }
          } else {
            const personal_params = {
              user_language: getLngFromCultureName(locale),
              wsc_account_code: '',
              chat_source: origin,
              showDocumentValidityAlert: true,
            };
            if (isMobile) {
              initParams = {
                cid: process.env.NEXT_PUBLIC_DIGITAL_ASSISTANT_CID, //'205d106b17323bdccede4dca5681a4c994b54562',
                sessionId: 'wsc_account_code',
                baseUrl: CHAT_SERVICE_URL,
                view: isMobile ? { mobileMode: 'detect', status: 'visible' } : { status: 'visible' },
                params: personal_params,
              };
            } else {
              initParams = {
                cid: process.env.NEXT_PUBLIC_DIGITAL_ASSISTANT_CID, //'205d106b17323bdccede4dca5681a4c994b54562',
                sessionId: 'wsc_account_code',
                view: { status: 'visible' },
                baseUrl: CHAT_SERVICE_URL,
                params: personal_params,
              };
            }
          }
          //DIGITAL ASSISTANT
          window.DigitalAssistant.init(initParams);
        } catch (error) {
          console.log('error init digital assistant');
        }
      }
    }
  }, [getSessionCacheProfile, isMobile, session, isChatForAuthenticated, locale, saldoPrelevabile]);

  const initStateDigitalAssistant = useCallback(async () => {
    setIsLoadedChat(true);
  }, []);

  return (
    <>
      {isLoadedChat && (
        <Script
          id="chatbot"
          strategy="lazyOnload"
          src={DIGITAL_ASSISTANT_URL}
          nonce={getNonce()}
          onLoad={() => {
            initDigitalAssistant();
          }}
        ></Script>
      )}
      <button
        className={`${styles.container} ${className ?? ''}`}
        onClick={
          !isAuthenticated && isChatForAuthenticated
            ? () => dispatch(setIsOpenLoginModalValue(true))
            : () => {
                if (!window.DigitalAssistant) initStateDigitalAssistant();
                else if (window.DigitalAssistant.statusInit !== window.DigitalAssistant.StatusInit.INITIALIZED) {
                  initStateDigitalAssistant();
                  initDigitalAssistant();
                } else window.DigitalAssistant.show();
              }
        }
      >
        <div>
          <Icon iconId={IconsEnum.COMMENT} className={styles.icon} color="var(--color-bg)" />
        </div>
        {text && <span>{text}</span>}
      </button>
    </>
  );
};
