import { Icon, IconsEnum } from 'components/Icons';
import Image, { ImageProps } from 'next/image';
import { SidebarGeneric, SidebarGenericType } from 'components/sidebarGeneric';
import { TextBoard, TextBoardType } from 'components/textBoard';
import { useForm, useWatch } from 'react-hook-form';

import { AccordionCms } from 'lib/datoCms/queries/getStaticPage';
import { BoxList } from 'components/boxList';
import { Button } from 'components/button';
import { ChatTrigger } from 'components/chatTrigger';
import { RadioButton } from 'components/inputs/radioButton';
import { RichText } from 'components/richText';
import { SimpleAccordion } from 'components/simpleAccordion';
import { TIPOLOGY_SEND_INFO } from 'utility/dashboard/constants';
import { Wrapper } from 'components/wrapper';
import { breakpoint } from 'utility/constant';
import { isClientSide } from 'utility/functions';
import { setIsOpenLoginModalValue } from 'features/modal/modalSlice';
import styles from './LayoutGeneric.module.scss';
import { useAppDispatch } from 'lib/centralStore';
import { useCallback } from 'react';
import { useInternalSession } from 'hooks/useInternalSession';
import { useSendInfoMutation } from 'features/api/userSlice';
import useWindowSize from 'hooks/useWindowSize';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

export type LayoutGenericProps = {
  sidebar?: SidebarGenericType;
  caption?: string;
  subtitle?: string;
  fullScreenImage?: ImageProps & {
    url: string;
  };
  image?: ImageProps & {
    url: string;
  };
  text?: string;
  accordion?: {
    title?: string;
    list?: AccordionCms[];
  };
  downloadPDF?: {
    title?: string;
    list: DownloadFilePDF[];
  };
  textMail?: string;
  staticPageRadioButtonBlock?: {
    textRadioButtonBlock: string;
    titleRadioButtonBlock: string;
    radioButtonBlockItem: { text: string }[];
    mail: string;
    subjectMail: string;
  };
  chatTriggerEnabled?: boolean;
  textChatTrigger?: string;
};

type DownloadFilePDF = TextBoardType & {
  fileName: string;
  label: string;
  url?: string;
};

export const LayoutGeneric = ({
  sidebar,
  caption,
  subtitle,
  image,
  fullScreenImage,
  text,
  accordion,
  downloadPDF,
  textMail,
  staticPageRadioButtonBlock,
  chatTriggerEnabled,
  textChatTrigger,
}: LayoutGenericProps) => {
  const { width } = useWindowSize();
  const isMobile = width! < breakpoint.md;
  const dispatch = useAppDispatch();

  const [sendMail, { isSuccess: isSuccessAuth, isError: isErrorAuth, status: statusAuth }] = useSendInfoMutation();

  const { session, isAuthenticated } = useInternalSession();

  const schemaValidation = z.object({
    bonus: z.string(),
  });
  type SchemaValidationType = z.infer<typeof schemaValidation>;

  const { handleSubmit, control } = useForm<SchemaValidationType>({
    resolver: zodResolver(schemaValidation),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const bonus = useWatch({ control, name: 'bonus' });

  const downloadFile = (fileName: string, url?: string) => {
    // TODO: replace by fetch
    if (isClientSide()) {
      window.open(url, '_blank', 'popup=yes');
    }
  };

  const submit = useCallback(
    (data: SchemaValidationType) => {
      if (session && isAuthenticated && textMail) {
        const text = textMail
          .replace('{{SUBJECT}}', staticPageRadioButtonBlock?.subjectMail ?? '')
          .replace('{{CONTRACT_CODE}}', session.user.contractCode)
          .replace('{{USERNAME}}', session.user.name ?? '')
          .replace('{{EMAIL}}', session.user.email ?? '')
          .replace('{{SELECTION}}', data.bonus);
        sendMail({
          from: session.user.email ?? '',
          subject: staticPageRadioButtonBlock?.subjectMail ?? '',
          text: text,
          to: staticPageRadioButtonBlock?.mail ?? '',
          type: TIPOLOGY_SEND_INFO.EMAIL,
        });
      } else if (!isAuthenticated) {
        dispatch(setIsOpenLoginModalValue(true));
      }
    },
    [session, sendMail, staticPageRadioButtonBlock, isAuthenticated, dispatch, textMail]
  );

  return (
    <div>
      {fullScreenImage && (
        <Wrapper noPaddingTop={true} bgColorTransparent={true}>
          <div className={styles.fullScreenImage}>
            <div className={styles.imageContainer}>
              <Image
                src={fullScreenImage.src}
                alt={fullScreenImage.alt ?? ''}
                title={fullScreenImage.title}
                fill
                style={{ objectFit: 'cover' }}
                className={styles.image}
              />
            </div>
          </div>
        </Wrapper>
      )}
      <div className={sidebar ? styles.containerWithSidebar : styles.container}>
        {sidebar && (
          <div className={styles.sidebarContainer}>
            <SidebarGeneric {...sidebar} />
          </div>
        )}
        <div className={styles.mainContentWithSidebar}>
          <Wrapper
            bgColorTransparent={
              !caption && !subtitle && !image && !text && (!accordion || accordion.list?.length === 0) && !downloadPDF
            }
          >
            <div className={styles.mainContent}>
              <div className={styles.innerContent}>
                {caption && <span className={styles.caption}>{caption}</span>}
                {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
                {image && (
                  <div className={styles.imageContainer}>
                    <Image
                      src={image.url}
                      alt={image.alt ?? ''}
                      title={image.title}
                      fill
                      style={{ objectFit: 'cover' }}
                      className={styles.image}
                    />
                  </div>
                )}
                {text && <RichText containerClassName={styles.text}>{text}</RichText>}
                {staticPageRadioButtonBlock && (
                  <form onSubmit={handleSubmit(submit)}>
                    <RichText containerClassName={styles.text}>
                      {staticPageRadioButtonBlock.textRadioButtonBlock}
                    </RichText>
                    <label className={styles.text}>{staticPageRadioButtonBlock.titleRadioButtonBlock}</label>
                    <RadioButton
                      control={control}
                      className={styles.listRadioButton}
                      isVertical
                      name="bonus"
                      options={staticPageRadioButtonBlock.radioButtonBlockItem.map((item) => ({
                        label: item.text,
                        value: item.text,
                      }))}
                    />
                    {bonus && (
                      <Button size="medium" visualizationType="primary" type="submit">
                        VAI
                      </Button>
                    )}
                  </form>
                )}
                {accordion && accordion.list && accordion.list.length > 0 && (
                  <BoxList title={accordion.title}>
                    {accordion.list?.map((accordionItem, index) => {
                      return (
                        <SimpleAccordion
                          className={styles.accordion}
                          key={accordionItem + '_' + index}
                          title={accordionItem.titolo}
                          hasCheckbox={false}
                          isInnerHtmlContent
                        >
                          {accordionItem.descrizione}
                        </SimpleAccordion>
                      );
                    })}
                  </BoxList>
                )}
                {downloadPDF && (
                  <BoxList title={downloadPDF.title}>
                    {downloadPDF.list.map((downloadPDFitem: DownloadFilePDF, index) => {
                      return (
                        <TextBoard
                          key={downloadPDFitem + '_' + index}
                          icon={IconsEnum.CONTRACT_PDF}
                          markedTitle={downloadPDFitem.title}
                          internalClassName="surface02"
                          button={
                            <button
                              className={styles.button}
                              onClick={() => downloadFile(downloadPDFitem.fileName, downloadPDFitem.url)}
                            >
                              {!isMobile ? (
                                downloadPDFitem.label
                              ) : (
                                <Icon
                                  className={styles.icon}
                                  color="var(--color-primary)"
                                  iconId={IconsEnum.DOWNLOAD}
                                />
                              )}
                            </button>
                          }
                        />
                      );
                    })}
                  </BoxList>
                )}
                {chatTriggerEnabled && <ChatTrigger text={textChatTrigger} isChatForAuthenticated />}
              </div>
            </div>
          </Wrapper>
        </div>
      </div>
    </div>
  );
};
