import styles from './BoxList.module.scss';

export type BoxListProps = {
  title?: string;
  children: React.ReactNode;
};

export const BoxList = ({ title, children }: BoxListProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.boxList}>
        <div className={styles.boxListTitle}>{title}</div>
        {children}
      </div>
    </div>
  );
};
