const ACCESS_BONUS: {
  [key: string]: 'listFunbonus' | 'listFreespin' | 'listBonusGold' | 'listBonusCash';
} = {
  cash: 'listBonusCash',
  gold: 'listBonusGold',
  freespin: 'listFreespin',
  funbonus: 'listFunbonus',
};

const HEIGHT_SKELETON_SLUG_BONUS: { [key: string]: string } = {
  cash: '32.7',
  gold: '35.5',
  freespin: '32.7',
  funbonus: '29.1',
};

const ITA_CALENDAR = {
  name: 'gregorian_it',
  months: [
    ['Gennaio', 'Gennaio'],
    ['Febbraio', 'Febbraio'],
    ['Marzo', 'Marzo'],
    ['Aprile', 'Aprile'],
    ['Maggio', 'Maggio'],
    ['Giugno', 'Giugno'],
    ['Luglio', 'Luglio'],
    ['Agosto', 'Agosto'],
    ['Settembre', 'Settembre'],
    ['Ottobre', 'Ottobre'],
    ['Novembre', 'Novembre'],
    ['Dicembre', 'Dicembre'],
  ],
  weekDays: [
    ['Sabato', 'Sab'],
    ['Domenica', 'Dom'],
    ['Lunedì', 'Lun'],
    ['Martedì', 'Mar'],
    ['Mercoledì', 'Mer'],
    ['Giovedì', 'Gio'],
    ['Venerdì', 'Ven'],
  ],
  digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  meridiems: [
    ['am', 'am'],
    ['pm', 'pm'],
  ],

  // Example- meridiems:[["AM","am"],["PM","pm"]]
};

const ACCESS_MY_BETS: {
  [key: string]: 'scommesseAperte' | 'scommesseChiuse' | 'scommesseLive';
} = {
  aperte: 'scommesseAperte',
  chiuse: 'scommesseChiuse',
  live: 'scommesseLive',
};

const TIPOLOGY_SPORT_MY_BETS = {
  SPORT: 'Sport',
  IPPICA: 'Ippica',
  VIRTUAL: 'Virtual',
};

enum TIPOLOGY_SEND_INFO {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
}

const VETRINA_LOTTERY = {
  TYPE_DEFAULT: 'undefined',
  TYPE_MILLIONDAY: 'vetrinaMillionDAY',
  TYPE_MILLIONDA: 'vetrinaMillionDAY',
  TYPE_10eLOTTO: 'vetrinaDieciELotto',
  TYPE_LOTTO: 'vetrinaLotto',
};

export {
  ACCESS_BONUS,
  ACCESS_MY_BETS,
  HEIGHT_SKELETON_SLUG_BONUS,
  ITA_CALENDAR,
  TIPOLOGY_SEND_INFO,
  TIPOLOGY_SPORT_MY_BETS,
  VETRINA_LOTTERY,
};
