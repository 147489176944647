import { HeaderTitle } from 'components/headerTitle';
import { LayoutGeneric } from 'components/layoutGeneric';
import { TabsWithAccordion } from 'components/tabsWithAccordion';
import useWindowSize from 'hooks/useWindowSize';
import { TabsWithAccordionType } from 'lib/datoCms/queries/getSeoData';
import { StaticPageCms } from 'lib/datoCms/queries/getStaticPage';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { breakpoint } from 'utility/constant';

type Props = {
  title: string;
  seoData?: TabsWithAccordionType;
  staticPage?: StaticPageCms;
  textChatTrigger?: string;
  chatTriggerActive?: boolean;
};

const PageContent: NextPage<Props> = (props) => {
  const { title, staticPage, chatTriggerActive, textChatTrigger, seoData } = props;
  const { width } = useWindowSize();
  const isMobile = width! < breakpoint.md;
  const router = useRouter();

  const itemSidebar = useMemo(() => {
    return staticPage?.sidebar?.navigazione.map((item) => {
      const menuItemBlock = item.navigationItemBlock[0].menuItemBlock;
      return {
        title: item.label,
        href: !menuItemBlock ? item.navigationItemBlock[0].href : undefined,
        list: menuItemBlock?.map((e) => {
          return {
            title: e.label,
            href: e.linkBlock[0].href,
          };
        }),
      };
    });
  }, [staticPage]);

  const findItemSlug = useMemo(() => {
    const paths = router.asPath.split('/');
    let findItem;
    itemSidebar?.forEach((item) => {
      if (item.href && `/${paths[paths.length - 1]}` === item.href) {
        findItem = item;
        return;
      } else if (item.list && item.list.length > 0) {
        item.list.forEach((e) => {
          if (e.href && `/${paths[paths.length - 2]}/${paths[paths.length - 1]}` === e.href) {
            findItem = e;
            return;
          }
        });
      }
    });
    return findItem;
  }, [itemSidebar, router.asPath]);

  const sidebar = useMemo(() => {
    if (itemSidebar)
      return {
        title: isMobile && findItemSlug ? findItemSlug?.title! : staticPage?.sidebar.nomeSidebar,
        item: itemSidebar,
        parent: staticPage?.parent?.parent ? staticPage?.parent.parent.slug : staticPage?.parent?.slug,
      };
    return undefined;
  }, [staticPage, isMobile, itemSidebar, findItemSlug]);

  const layoutGeneric = useMemo(() => {
    return {
      caption: staticPage?.eyelet,
      subtitle: staticPage?.subtitle,
      fullScreenImage: staticPage?.fullScreenImage,
      image: staticPage?.image,
      text: staticPage?.text,
      accordion: {
        title: staticPage?.titleAccordionBlock,
        list: staticPage?.accordionList,
      },
      downloadPDF:
        staticPage?.downloadableFiles && staticPage?.downloadableFiles.length > 0
          ? {
              title: staticPage?.titleFilesBlock,
              list: staticPage?.downloadableFiles.map((file) => {
                return {
                  title: file.title,
                  label: 'Scarica',
                  url: file.link[0].href ?? file.link[0].file?.url,
                  fileName: file.title,
                };
              }),
            }
          : undefined,
      sidebar: sidebar,
      textMail: staticPage?.textMail,
      staticPageRadioButtonBlock: staticPage?.staticPageRadioButtonBlock,
      chatTriggerEnabled: chatTriggerActive,
      textChatTrigger,
    };
  }, [staticPage, sidebar, chatTriggerActive, textChatTrigger]);

  return (
    <div>
      <HeaderTitle title={title} />
      <LayoutGeneric {...layoutGeneric} />
      <TabsWithAccordion tabs={seoData?.tabs ?? []} />
    </div>
  );
};

export default PageContent;
