import { Icon, IconsEnum } from 'components/Icons';
import { RadioButton } from 'components/inputs/radioButton';
import { RichText } from 'components/richText';
import styles from './TextBoard.module.scss';

export type TextBoardProps = {
  options?: TextOptionsProps[];
  onChange?: (e: any) => void;
  title?: string;
  icon?: IconsEnum;
  markedTitle?: string;
  subtitle?: string | React.ReactNode;
  button?: React.ReactNode;
  btnOnBottom?: boolean;
  btnAlignLeft?: boolean;
  children?: React.ReactNode;
  selectedValue?: string;
  internalClassName?: string;
};

export type TextOptionsProps = {
  id: number;
  label: string;
  value: string;
  checked?: boolean;
};

export const TextBoard = ({
  options,
  onChange,
  title,
  markedTitle,
  subtitle,
  button,
  icon,
  btnOnBottom = false,
  btnAlignLeft = false,
  children,
  selectedValue,
  internalClassName,
}: TextBoardProps) => {
  return (
    <div
      className={`${styles.container} ${button && !btnOnBottom ? styles.withButton : ''} ${
        internalClassName ? styles[internalClassName] : ''
      }`}
    >
      <div className={`${!btnAlignLeft && button ? styles.flex : ''}`}>
        <div>
          <h2 className={styles.title}>
            {title && <RichText>{title}</RichText>}
            {icon ? (
              <div className={styles.icon}>
                <div className={styles.icon}>
                  <Icon iconId={icon} color="var(--color-on-bg-primary)"></Icon>
                </div>
                <span>{markedTitle}</span>
              </div>
            ) : (
              <span>{markedTitle}</span>
            )}
          </h2>
          {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
          {children && <div className={styles.children}>{children}</div>}
        </div>
        <div className={`${styles.btnContainer} ${btnAlignLeft ? styles.btnAlignLeft : ''}`}>{button}</div>
      </div>
      {options && (
        <RadioButton
          name={title ?? markedTitle ?? options[0].label}
          options={options}
          onChange={onChange}
          defaultValue={selectedValue}
        />
      )}
      {/* {children && <div className={styles.children}>{children}</div>} */}
    </div>
  );
};
