import { BaseItemFilter } from 'components/scommesseFilterTertiary/ScommesseFilterTertiary';
import { useTranslation } from 'hooks/useLingUI';
import { ChangeEvent, InputHTMLAttributes } from 'react';
import { Controller, FieldValues, Path, UseControllerProps } from 'react-hook-form';
import { isControlledInput } from '../utils';
import style from './RadioButton.module.scss';

export type RadioOptionProps<S extends any = any> = Omit<BaseItemFilter, 'value'> & {
  value: S;
};

export type RadioButtonProps<S extends any = any> = {
  name: string;
  isVertical?: boolean;
  options: Array<RadioOptionProps<S>>;
  hasMarginBottom?: boolean;
  hasMarginLeft?: boolean;
  hasMarginRight?: boolean;
  hasMarginTop?: boolean;
  className?: string;
  defaultValue?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
} & InputHTMLAttributes<HTMLInputElement>;

export type ControlledRadioButtonProps<
  T extends FieldValues = FieldValues,
  K extends Path<T> = Path<T>,
  S extends any = any
> = RadioButtonProps<S> & UseControllerProps<T, K>;

export function RadioButton<T extends FieldValues>(props: ControlledRadioButtonProps<T>): JSX.Element;
export function RadioButton<S extends any>(props: RadioButtonProps): JSX.Element;
export function RadioButton(props: RadioButtonProps | ControlledRadioButtonProps): JSX.Element {
  const { t } = useTranslation();
  const { name, options } = props;
  if (isControlledInput<ControlledRadioButtonProps>(props)) {
    const { control, rules } = props;
    return (
      <Controller
        control={control}
        key={`${props.name}_controller_toggle`}
        name={name}
        rules={rules}
        defaultValue={props.defaultValue}
        render={({ field: { name, onBlur, onChange, ref, ...field } }) => (
          <div
            {...props}
            className={`${style.container} 
            ${props.isVertical && style.vertical}
            ${props.hasMarginTop ? style.containerMarginTop : ''}
            ${props.hasMarginRight ? style.containerMarginRight : ''}
            ${props.hasMarginBottom ? style.containerMarginBottom : ''}
            ${props.hasMarginLeft ? style.containerMarginLeft : ''}
            ${props.className}
            `}
          >
            {options.map(({ label, labelTrKey, value }, index) => {
              return (
                <div className={style.inputContainer} key={`buttonContainer_${name}_${index}`}>
                  <input
                    {...field}
                    name={name}
                    id={`${name}_${index}`}
                    key={`${name}_${index}`}
                    type="radio"
                    value={value}
                    ref={ref}
                    checked={field.value === value}
                    onChange={(e) => {
                      onChange(value);
                      props.onChange && props.onChange(e);
                    }}
                    onBlur={(e) => {
                      onBlur();
                      props.onBlur && props.onBlur(e);
                    }}
                  />
                  <label htmlFor={`${name}_${index}`}>{t(labelTrKey, label)}</label>
                </div>
              );
            })}
          </div>
        )}
      />
    );
  }
  return (
    <div className={`${style.container} ${props.isVertical && style.vertical} ${props.className && props.className} `}>
      {options.map(({ label, labelTrKey, value }, index) => {
        return (
          <div className={style.inputContainer} key={`buttonContainer_${name}_${index}`}>
            <input
              id={`${name}_${index}`}
              key={`${name}_${index}`}
              type="radio"
              value={value}
              checked={props.defaultValue === value}
              onChange={(e) => {
                props.onChange && props.onChange(e);
              }}
              onBlur={(e) => {
                props.onBlur && props.onBlur(e);
              }}
            />
            <label htmlFor={`${name}_${index}`}>{t(labelTrKey, label)}</label>
          </div>
        );
      })}
    </div>
  );
}
